import React, { useContext, useState } from "react";
import { NavLink } from "react-router-dom";
import "./SideBar.scss";
import {
  MdDashboard,
  MdAreaChart,
  MdSignalCellularAlt,
  MdLogout,
  MdFileUpload,
} from "react-icons/md";
import { BiSolidUserCircle } from "react-icons/bi";
import UserService from "../../services/UserService";
import DisableArea from "../DisableArea/DisableArea";
import { SunriseContext } from "../../App";

function Container({ disable, children }) {
  const { organisation } = useContext(SunriseContext);
  const [nav, setNav] = useState(true);

  const Change = () => {
    setNav((prevNav) => !prevNav);
  };

  return (
    <div>
      <div id="sidebar" className={nav ? "" : "closeNav"}>
        <div id="menu">
          <div className={`menuWrap ${nav ? "" : "small"}`}>
            <DisableArea disable={disable}>
              <NavLink exact="true" to="/profile" className="menuItemWrap">
                <div className="menuItemAcc" id="philosophy">
                  <div className="menuIcon">
                    <BiSolidUserCircle />{" "}
                  </div>
                  <h3>
                    <UserService.GetUserName />
                  </h3>
                </div>
              </NavLink>
            </DisableArea>
            <NavLink
              exact="true"
              to="/sign-in"
              className="menuItemWrap"
              onClick={UserService.doLogout}
            >
              <div className="menuItemAcc" id="philosophy">
                <div className="menuIcon">
                  <MdLogout />
                </div>
                <h3>Log Out</h3>
              </div>
            </NavLink>
          </div>
          <div className="burgerContainer">
            <div
              id="burgerArrow"
              className={`cookBurger ${nav ? "clickBurger" : ""} `}
              onClick={Change}
            >
              <span></span>
              <span></span>
              <span></span>
            </div>
            <h3 onClick={Change}>Collapse</h3>
          </div>

          <DisableArea disable={disable}>
            <NavLink to="/" className="menuItemWrap">
              <div className="menuItemAcc" id="philosophy">
                <div className="menuIcon">
                  <MdDashboard />
                </div>
                <h3>Dasboard</h3>
              </div>
            </NavLink>

            {!["ELS", "RTM"].includes(organisation) && (
              <NavLink to="/parameters-used" className="menuItemWrap">
                <div className="menuItemAcc" id="mission">
                  <div className="menuIcon">
                    <MdAreaChart />
                  </div>
                  <h3>Metrics</h3>
                </div>
              </NavLink>
            )}

            <NavLink to="/grafana-diagram" className="menuItemWrap">
              <div className="menuItemAcc" id="diagram">
                <div className="menuIcon">
                  <MdSignalCellularAlt />
                </div>
                <h3>Demand Prediction</h3>
              </div>
            </NavLink>

            {organisation !== "ELS" && (
              <NavLink to="/upload-file" className="menuItemWrap">
                <div className="menuItemAcc" id="diagram">
                  <div className="menuIcon">
                    <MdFileUpload />
                  </div>
                  <h3>Upload File</h3>
                </div>
              </NavLink>
            )}
          </DisableArea>
        </div>
      </div>
    </div>
  );
}

export default Container;
