import React, { useContext, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ResourceListService from "../../services/ResourceListService";
import Loader from "../../components/Loader/Loader.js";
import { useKeycloak } from "@react-keycloak/web";
import { SunriseContext } from "../../App.js";
import { Box, Grid, Alert, FormControl, MenuItem, Button } from "@mui/material";
import organisationData from "../../data/ciiData.json";
import {
  StyledInputLabel,
  StyledSelect,
} from "../../components/FormElements/CustomSelect";
import "./ResourceList.scss";

function ResourceList() {
  const { keycloak } = useKeycloak();
  const { organisation } = useContext(SunriseContext);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ show: false, type: "", message: "" });

  const [timePeriods, setTimePeriods] = useState([]);
  const [primaryDropdownData, setPrimaryDropdownData] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedValues, setSelectedValue] = useState({
    primary: "",
    secondary: "",
    interval: "",
  });

  const [metrics, setMetrics] = useState([]);

  const timeIntervals = {
    hourly: { id: "H", name: "Hourly" },
    daily: { id: "D", name: "Daily" },
    monthly: { id: "M", name: "Monthly" },
  };

  const timeIntervalsArray = [
    { id: "H", name: "Hourly" },
    { id: "D", name: "Daily" },
    { id: "M", name: "Monthly" },
  ];

  useEffect(() => {
    if (organisationData && organisation) {
      const data = organisationData.find(
        (element) => element.name === organisation
      );
      if (["TT", "RTM"].includes(organisation)) {
        let filteredData = { ...data.dropdownData };
        filteredData.options = data.dropdownData.options.filter(
          (item) => item.onlyDashboard !== true
        );
        setPrimaryDropdownData(filteredData);
      } else {
        setPrimaryDropdownData(data.dropdownData);
      }
    }
  }, [organisation]);

  useEffect(() => {
    if (selectedItem) {
      if (selectedItem.subOptions && selectedItem.subOptions.length > 0) {
        if (selectedValues.secondary !== "") {
          let option = selectedItem.subOptions.find(
            (element) => element.id === selectedValues.secondary
          );
          if (option) {
            let tempTimePeriods = [];
            Object.keys(timeIntervals).forEach((key) => {
              let existingDataObjects = option[key];
              if (existingDataObjects && existingDataObjects.length > 0) {
                tempTimePeriods.push(timeIntervals[key]);
              }
            });
            handleTimePeriodChange(tempTimePeriods);
          }
        }
      } else {
        let tempTimePeriods = [];
        Object.keys(timeIntervals).forEach((key) => {
          let existingDataObjects = selectedItem[key];
          if (existingDataObjects && existingDataObjects.length > 0) {
            tempTimePeriods.push(timeIntervals[key]);
          }
        });
        handleTimePeriodChange(tempTimePeriods);
      }
    }
  }, [selectedItem, selectedValues]); //eslint-disable-line react-hooks/exhaustive-deps

  const onPrimaryDropdownChange = (e) => {
    const item = primaryDropdownData.options.find(
      (element) => element.id === e.target.value
    );
    setSelectedItem(item ?? null);
    setSelectedValue((prevState) => ({
      ...prevState,
      primary: e.target.value,
      secondary: "",
      interval: "",
    }));
  };

  const onSecondaryDropdownChange = (e) => {
    setSelectedValue((prevState) => ({
      ...prevState,
      secondary: e.target.value,
      timePeriod: "",
    }));
  };

  const onHandleTimePeriodFieldChange = (e) => {
    let time = e.target.value;
    onTimePeriodChange(time);
  };

  const onTimePeriodChange = (time) => {
    setSelectedValue((prevState) => ({
      ...prevState,
      interval: time,
    }));
  };

  const onGetMetrics = (event) => {
    event.preventDefault();
    setLoading(true);
    setAlert({ show: false, type: "", message: "" });
    let parameters = { is_production: true };
    let orgParameter = ["INS", "HQM"].includes(organisation)
      ? "category"
      : "place";
    if (selectedValues.interval !== "") {
      parameters.interval = selectedValues.interval;
    }
    if (selectedItem !== null) {
      if (selectedItem.subOptions && selectedValues.secondary !== "") {
        parameters[orgParameter] = selectedValues.secondary;
      } else if (!selectedItem.subOptions && selectedItem.primary !== "") {
        parameters[orgParameter] = selectedValues.primary;
      }
    }
    ResourceListService.getMetrics(keycloak.token, organisation, parameters)
      .then((response) => {
        if (response.data.length > 0) {
          setMetrics(response.data);
          setAlert({ show: false, type: "", message: "" });
        } else {
          setAlert({
            show: true,
            type: "info",
            message: "There are no available metrics data at the moment.",
          });
          setMetrics([]);
        }
      })
      .catch((error) => {
        setAlert({
          show: true,
          type: "error",
          message: "Failed to fetch metrics data. Try again later.",
        });
        setMetrics([]);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const sortItems = (items) => {
    return items.sort((a, b) => {
      if (a.name === "All places") return 1;
      if (b.name === "All places") return -1;
      if (a.name === "Global") return -1;
      if (b.name === "Global") return 1;
      return a.name.localeCompare(b.name);
    });
  };
  const handleTimePeriodChange = (times) => {
    setTimePeriods(times);
    if (times.length === 1) {
      onTimePeriodChange(times[0].id);
    }
  };

  return (
    <div id="resource-list-wrapper">
      <div className="dashChart">
        <form onSubmit={onGetMetrics}>
          <div className="flexBox">
            <Box sx={{ width: "100%" }}>
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <Grid item xs={12} md={12}>
                  <div
                    className="chartBox chartOptions flex-column"
                    style={{ gap: "16px", alignItems: "flex-start" }}
                  >
                    <h2 className="grafanaTitle">Metrics</h2>
                    <p>
                      Choose the desired categories & time period (interval)
                      from the options below to get the corresponding metrics.{" "}
                    </p>
                    <div className="dashChart flexBox">
                      {primaryDropdownData && (
                        <>
                          <FormControl fullWidth={true}>
                            <StyledInputLabel
                              htmlFor={`${primaryDropdownData.primaryTitle}-1`}
                            >
                              {primaryDropdownData.primaryTitle}
                            </StyledInputLabel>
                            <StyledSelect
                              labelId={`${primaryDropdownData.primaryTitle}-1`}
                              label={primaryDropdownData.primaryTitle}
                              onChange={onPrimaryDropdownChange}
                              value={selectedValues.primary}
                              required
                            >
                              <MenuItem value=""></MenuItem>
                              {sortItems(primaryDropdownData.options).map(
                                (item, index) => (
                                  <MenuItem
                                    key={`${index}-${item}`}
                                    value={item.id}
                                  >
                                    {item.name}
                                  </MenuItem>
                                )
                              )}
                            </StyledSelect>
                          </FormControl>
                          {selectedItem && selectedItem.subOptions && (
                            <FormControl fullWidth={true}>
                              <StyledInputLabel
                                htmlFor={`${primaryDropdownData.secondaryTitle}-2`}
                              >
                                {primaryDropdownData.secondaryTitle}
                              </StyledInputLabel>
                              <StyledSelect
                                labelId={`${primaryDropdownData.secondaryTitle}-2`}
                                label={primaryDropdownData.secondaryTitle}
                                disabled={
                                  selectedItem === null ||
                                  selectedItem.subOptions.length === 0
                                }
                                onChange={onSecondaryDropdownChange}
                                value={selectedValues.secondary}
                                required
                              >
                                {selectedItem && <MenuItem value=""></MenuItem>}
                                {selectedItem &&
                                  sortItems(selectedItem.subOptions).map(
                                    (item, index) => (
                                      <MenuItem
                                        key={`${index}-${item.id}`}
                                        value={item.id}
                                      >
                                        {item.name}
                                      </MenuItem>
                                    )
                                  )}
                              </StyledSelect>
                            </FormControl>
                          )}
                          <FormControl fullWidth={true}>
                            <StyledInputLabel htmlFor="timePeriod">
                              Time period
                            </StyledInputLabel>
                            <StyledSelect
                              labelId="timePeriod"
                              label="timePeriod"
                              onChange={onHandleTimePeriodFieldChange}
                              value={selectedValues.interval}
                              disabled={timePeriods.length === 0}
                              required
                            >
                              <MenuItem value=""></MenuItem>
                              {timePeriods.map((period, index) => (
                                <MenuItem
                                  key={`${index}-${period}`}
                                  value={period.id}
                                >
                                  {period.name}
                                </MenuItem>
                              ))}
                            </StyledSelect>
                          </FormControl>
                        </>
                      )}
                    </div>
                    <br />
                    <div className="dashChart">
                      <FormControl size="small" fullWidth={true}>
                        <div className="flexBox flexEnd">
                          <Button
                            variant="contained"
                            size="medium"
                            type="submit"
                          >
                            Get metrics
                          </Button>
                        </div>
                      </FormControl>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Box>
          </div>
        </form>
      </div>
      {loading && <Loader transparent={true} />}
      {alert.show && (
        <div className="flexBox flexCenter">
          <Alert severity={alert.type}>{alert.message}</Alert>
        </div>
      )}
      {metrics.map((item, index) => (
        <div className="dashChart" key={index}>
          <h3 className="metrics-title">
            {["INS", "HQM"].includes(organisation)
              ? item.category.replaceAll("_", " ")
              : item.place.replaceAll("_", " ")}{" "}
            {
              timeIntervalsArray.find((element) => element.id === item.interval)
                .name
            }{" "}
            Metrics
          </h3>
          <div className="chartBox">
            <MetricsTable metrics={item.metrics} />
          </div>
        </div>
      ))}
    </div>
  );
}

export default ResourceList;

const StyledMetricsTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // color: "white",
    fontSize: 16,
    textTransform: "capitalize",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
    borderCollapse: true,
    border: "none",
  },
}));

const StyledMetricsTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "black",
    color: "white",
    fontSize: 16,
    textTransform: "capitalize",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
    borderCollapse: true,
    border: "none",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: "#1F1F1F",
  color: "white",
}));

function MetricsTable({ metrics }) {
  return (
    <div>
      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: 700 }}
          aria-label="customized table"
          style={{ borderCollapse: "collapse" }}
        >
          <TableHead>
            <StyledTableRow>
              <StyledMetricsTableCell align="center">
                Name
              </StyledMetricsTableCell>
              <StyledMetricsTableCell align="center">
                Value
              </StyledMetricsTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {Object.keys(metrics).map((key, index) => (
              <StyledMetricsTableRow key={index}>
                <StyledTableCell align="center">{key}</StyledTableCell>
                <StyledTableCell align="center">{metrics[key]}</StyledTableCell>
              </StyledMetricsTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
