import React, { useContext, useEffect, useState } from "react";
import "./grafanaDiagram.scss";

import FormControl from "@mui/material/FormControl";

import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Slider from "@mui/material/Slider";
import Button from "@mui/material/Button";
import { Alert, Box, Grid, MenuItem } from "@mui/material";
import organisationData from "../../data/ciiData.json";
import { SunriseContext } from "../../App";
import {
  StyledInputLabel,
  StyledSelect,
} from "../../components/FormElements/CustomSelect";
import dayjs from "dayjs";
import Loader from "../../components/Loader/Loader";

function GrafanaDiagram() {
  const [timePeriods, setTimePeriods] = useState([]);
  const [primaryDropdownData, setPrimaryDropdownData] = useState(null);
  const [selectedPrimaryDropdownItem, setSelectedPrimaryDropdownItem] =
    useState(null);
  const [dropdownValues, setDropdownValues] = useState({
    primary: "",
    secondary: "",
    timePeriod: "",
  });

  const [diagrams, setDiagrams] = useState([]);
  const [filteredDiagrams, setFilteredDiagrams] = useState([]);
  const [noDiagramAlert, setNoDiagramAlert] = useState(false);

  const [values, setValues] = useState({
    covid: false,
    date: "",
    scale: 6,
    dateSelection: false,
  });

  const [loading, setLoading] = useState(false);

  const [sliderValues, setSliderValues] = useState({
    min: 0,
    max: 0,
  });

  const { organisation } = useContext(SunriseContext);

  const timePeriodOptions = {
    hourly: { id: "hourly", name: "Hourly" },
    daily: { id: "daily", name: "Daily" },
    monthly: { id: "monthly", name: "Monthly" },
  };

  const sortItems = (items) => {
    return items.sort((a, b) => {
      if (a.name === "All places") return 1;
      if (b.name === "All places") return -1;
      if (a.name === "Global") return -1;
      if (b.name === "Global") return 1;
      return a.name.localeCompare(b.name);
    });
  };

  useEffect(() => {
    if (organisationData && organisation) {
      const data = organisationData.find(
        (element) => element.name === organisation
      );
      if (data.dropdownData.options.length === 1) {
        setSelectedPrimaryDropdownItem(data.dropdownData.options[0]);
        setDropdownValues((prevValues) => ({
          ...prevValues,
          primary: data.dropdownData.options[0].id,
        }));
      }
      if (["TT", "RTM"].includes(organisation)) {
        let filteredData = {...data.dropdownData};
        filteredData.options = data.dropdownData.options.filter(
          (item) => item.onlyDashboard !== true
        );
        setPrimaryDropdownData(filteredData);
      } else {
        setPrimaryDropdownData(data.dropdownData);
      }
      
    }
  }, [organisation]);

  const onCovidChange = () => {
    setValues((prevValues) => ({
      ...prevValues,
      covid: !prevValues.covid,
    }));
  };

  const dateSelectionhandler = () => {
    setValues((prevValues) => ({
      ...prevValues,
      dateSelection: !prevValues.dateSelection,
    }));
  };

  const onDateChange = (event) => {
    let a = event.$y + "/";
    let b = event.$M + 1;
    let c = "/" + event.$D;
    setValues((prevValues) => ({
      ...prevValues,
      date: a + b + c,
    }));
  };

  const onChangeScale = (event) => {
    setValues((prevValues) => ({
      ...prevValues,
      scale: event.target.value,
    }));
  };

  const onPrimaryDropdownChange = (e) => {
    const item = primaryDropdownData.options.find(
      (element) => element.id === e.target.value
    );
    setSelectedPrimaryDropdownItem(item ?? null);
    setDropdownValues((prevState) => ({
      ...prevState,
      primary: e.target.value,
      secondary: "",
      timePeriod: "",
    }));
    clearStates(true);
  };

  const onSecondaryDropdownChange = (e) => {
    setDropdownValues((prevState) => ({
      ...prevState,
      secondary: e.target.value,
      timePeriod: "",
    }));
    clearStates(true);
  };

  const onHandleSliderValues = (time) => {
    if (time === "hourly") {
      setSliderValues({ min: 0, max: 24 });
    } else if (time === "daily") {
      setSliderValues({ min: 0, max: 20 });
    } else if (time === "monthly") {
      setSliderValues({ min: 0, max: 12 });
    } else {
      setSliderValues({ min: 0, max: 0 });
    }
  };

  const onHandleTimePeriodFieldChange = (e) => {
    let time = e.target.value;
    onTimePeriodChange(time);
  }

  const onTimePeriodChange = (time) => {
    setDropdownValues((prevState) => ({
      ...prevState,
      timePeriod: time,
    }));
    onHandleSliderValues(time);
    if (time !== "") {
      if (
        selectedPrimaryDropdownItem.subOptions &&
        selectedPrimaryDropdownItem.subOptions.length > 0
      ) {
        let tempDiagrams = selectedPrimaryDropdownItem.subOptions.find(
          (item) => item.id === dropdownValues.secondary
        );
        if (tempDiagrams) {
          let nonHistoricDiagrams = tempDiagrams[time].filter(
            (element) => element.historic === false
          );
          setDiagrams(nonHistoricDiagrams);
          setNoDiagramAlert(nonHistoricDiagrams.length === 0);
          setFilteredDiagrams((prevValue) => {
            return nonHistoricDiagrams.length === 0 ? [] : prevValue;
          });
        }
      } else {
        let nonHistoricDiagrams = selectedPrimaryDropdownItem[
          time
        ].filter((element) => element.historic === false);
        setDiagrams(nonHistoricDiagrams);
        setNoDiagramAlert(nonHistoricDiagrams.length === 0);
        setFilteredDiagrams((prevValue) => {
          return nonHistoricDiagrams.length === 0 ? [] : prevValue;
        });
      }
    } else {
      clearStates();
    }
  };

  useEffect(() => {
    if (selectedPrimaryDropdownItem) {
      if (selectedPrimaryDropdownItem.subOptions) {
        if (dropdownValues.secondary !== "") {
          let option = selectedPrimaryDropdownItem.subOptions.find(
            (element) => element.id === dropdownValues.secondary
          );
          if (option) {
            let tempTimePeriods = [];
            Object.keys(timePeriodOptions).forEach((key) => {
              let diagrams = option[key].filter(
                (element) => element.historic === false
              );
              if (diagrams && diagrams.length > 0) {
                tempTimePeriods.push(timePeriodOptions[key]);
              }
            });
            handleTimePeriodChange(tempTimePeriods)
            setNoDiagramAlert(tempTimePeriods.length === 0);
            setFilteredDiagrams((prevValue) => {
              return tempTimePeriods.length === 0 ? [] : prevValue;
            });
          }
        }
      } else {
        let tempTimePeriods = [];
        Object.keys(timePeriodOptions).forEach((key) => {
          let diagrams = selectedPrimaryDropdownItem[key].filter(
            (element) => element.historic === false
          );
          if (diagrams && diagrams.length > 0) {
            tempTimePeriods.push(timePeriodOptions[key]);
          }
        });
        handleTimePeriodChange(tempTimePeriods)
        setNoDiagramAlert(tempTimePeriods.length === 0);
        setFilteredDiagrams((prevValue) => {
          return tempTimePeriods.length === 0 ? [] : prevValue;
        });
      }
    }
  }, [selectedPrimaryDropdownItem, dropdownValues]); //eslint-disable-line react-hooks/exhaustive-deps

  const calculatePredictionEndDate = (dateToUpdate, startDate) => {
    if (dropdownValues.timePeriod === "hourly") {
      return dateToUpdate.setHours(startDate.getHours() + values.scale);
    } else if (dropdownValues.timePeriod === "daily") {
      return dateToUpdate.setDate(startDate.getDate() + values.scale);
    } else {
      return dateToUpdate.setMonth(startDate.getMonth() + values.scale);
    }
  };

  const onPredictHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    setFilteredDiagrams([]);
    let tempDiagrams = diagrams.map((diagram) => ({ ...diagram }));

    if (organisation === "ELS") {
      let elsStartDate = new Date();
      let elsEndDate = new Date();
      //ELS start date = today - 1 week
      elsStartDate.setDate(elsStartDate.getDate() - 7);
      //ELS end date = today + 2 days
      elsEndDate.setDate(elsEndDate.getDate() + 1);
      for (let i = 0; i < tempDiagrams.length; i++) {
        if (!tempDiagrams[i].performance) {
          let url = new URL(tempDiagrams[i].url);
          url.searchParams.set("from", elsStartDate.getTime());
          url.searchParams.set("to", elsEndDate.getTime());
          tempDiagrams[i].url = url.href;
        }
      }
      setFilteredDiagrams(tempDiagrams);
    } else if (values.dateSelection) {
      if (values.date !== "") {
        let startDate = new Date(values.date);
        let tempDate = new Date(startDate);
        let toDate = calculatePredictionEndDate(tempDate, startDate);

        for (let i = 0; i < tempDiagrams.length; i++) {
          if (!tempDiagrams[i].performance) {
            let url = new URL(tempDiagrams[i].url);
            url.searchParams.set("from", startDate.getTime());
            url.searchParams.set("to", new Date(toDate).getTime());
            tempDiagrams[i].url = url.href;
          }
        }
        setFilteredDiagrams(tempDiagrams);
      }
    } else {
      setFilteredDiagrams(tempDiagrams);
    }

    setLoading(false);
  };

  const clearStates = (clearTime = false) => {
    setDiagrams([]);
    setNoDiagramAlert(false);
    if (clearTime) {
      setTimePeriods([]);
    }
  };

  const editGrafanaUrlPerEnvironment = (url) => {
    let baseUrl = url
      .split("//")[1]
      .substring(url.split("//")[1].indexOf("/") + 1);
    return process.env.REACT_APP_GRAFANA_URL + "/" + baseUrl;
  };

  const handleTimePeriodChange = (times) => {
    setTimePeriods(times);
    if(times.length === 1) {
      onTimePeriodChange(times[0].id)
    }
  }

  let datePickerStartDate = ["TT","RTM"].includes(organisation) ? new Date("2019-01-01") : new Date();

  return (
    <div id="grafana-diagram-container">
      <div className="dashChart">
        <form onSubmit={onPredictHandler}>
          <div className="chartOptions chartBox grafana-inner flexColumn">
            <h2 className="grafanaTitle">Demand Prediction</h2>
            <Box sx={{ width: "100%" }}>
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <Grid item xs={12} md={12}>
                  <p style={{ marginTop: "-24px" }}>
                    Select prediction's options
                    {organisation !== "ELS" && (
                      <>
                        , time period, start date and duration
                        {dropdownValues.timePeriod === ""
                          ? " (depending on time period)"
                          : dropdownValues.timePeriod === "hourly"
                          ? " in hours (0-24)"
                          : dropdownValues.timePeriod === "daily"
                          ? " in days (0-20)"
                          : dropdownValues.timePeriod === "monthly"
                          ? " in months (0-12)"
                          : ""}
                      </>
                    )}
                    .
                  </p>

                  <div className="dashChart flexBox">
                    {primaryDropdownData && (
                      <>
                        <FormControl fullWidth={true}>
                          <StyledInputLabel
                            htmlFor={`${primaryDropdownData.primaryTitle}-1`}
                          >
                            {primaryDropdownData.primaryTitle}
                          </StyledInputLabel>
                          <StyledSelect
                            labelId={`${primaryDropdownData.primaryTitle}-1`}
                            label={primaryDropdownData.primaryTitle}
                            onChange={onPrimaryDropdownChange}
                            value={dropdownValues.primary}
                            required
                          >
                            <MenuItem value=""></MenuItem>
                            {sortItems(primaryDropdownData.options).map(
                              (item, index) => (
                                <MenuItem
                                  key={`${index}-${item}`}
                                  value={item.id}
                                >
                                  {item.name}
                                </MenuItem>
                              )
                            )}
                          </StyledSelect>
                        </FormControl>

                        {selectedPrimaryDropdownItem &&
                          selectedPrimaryDropdownItem.subOptions && (
                            <FormControl fullWidth={true}>
                              <StyledInputLabel
                                htmlFor={`${primaryDropdownData.secondaryTitle}-2`}
                              >
                                {primaryDropdownData.secondaryTitle}
                              </StyledInputLabel>
                              <StyledSelect
                                labelId={`${primaryDropdownData.secondaryTitle}-2`}
                                label={primaryDropdownData.secondaryTitle}
                                disabled={
                                  selectedPrimaryDropdownItem === null ||
                                  selectedPrimaryDropdownItem.subOptions
                                    .length === 0
                                }
                                onChange={onSecondaryDropdownChange}
                                value={dropdownValues.secondary}
                                required
                              >
                                {selectedPrimaryDropdownItem && (
                                  <MenuItem value=""></MenuItem>
                                )}
                                {selectedPrimaryDropdownItem &&
                                  sortItems(
                                    selectedPrimaryDropdownItem.subOptions
                                  ).map((item, index) => (
                                    <MenuItem
                                      key={`${index}-${item.id}`}
                                      value={item.id}
                                    >
                                      {item.name}
                                    </MenuItem>
                                  ))}
                              </StyledSelect>
                            </FormControl>
                          )}
                      </>
                    )}
                    <FormControl fullWidth={true}>
                      <StyledInputLabel htmlFor="timePeriod">
                        Time period
                      </StyledInputLabel>
                      <StyledSelect
                        labelId="timePeriod"
                        label="timePeriod"
                        onChange={onHandleTimePeriodFieldChange}
                        value={dropdownValues.timePeriod}
                        disabled={timePeriods.length === 0}
                        required
                      >
                        <MenuItem value=""></MenuItem>
                        {timePeriods.map((period, index) => (
                          <MenuItem
                            key={`${index}-${period}`}
                            value={period.id}
                          >
                            {period.name}
                          </MenuItem>
                        ))}
                      </StyledSelect>
                    </FormControl>
                  </div>
                </Grid>
              </Grid>
            </Box>

            {organisation !== "ELS" && (
              <div className="dashChart">
                <FormControl size="small" fullWidth={true}>
                  <div className="flexBox flexColumn">
                    <div className="grafanaType">
                      <FormControlLabel
                        control={
                          <Checkbox
                            onClick={dateSelectionhandler}
                            style={{ color: "#35afaf" }}
                            checked={values.dateSelection}
                          />
                        }
                        label="Enable date selection"
                      />
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          format="YYYY/MM/DD"
                          onChange={onDateChange}
                          minDate={dayjs(datePickerStartDate)}
                          slotProps={{
                            textField: { required: true },
                          }}
                          disabled={!values.dateSelection}
                        />
                      </LocalizationProvider>
                      <div
                        className="grafana-slider"
                        style={{
                          visibility: `${
                            dropdownValues.timePeriod === ""
                              ? "hidden"
                              : "visible"
                          }`,
                        }}
                      >
                        <span>{sliderValues.min}</span>
                        <Slider
                          size="medium"
                          defaultValue={values.scale}
                          aria-label="medium"
                          min={sliderValues.min}
                          max={sliderValues.max}
                          valueLabelDisplay="auto"
                          onChange={onChangeScale}
                          style={{
                            color: `${
                              !values.dateSelection ? "#4F4F4F" : "#35afaf"
                            }`,
                          }}
                          value={values.scale}
                          disabled={!values.dateSelection}
                        />
                        <span>{sliderValues.max}</span>
                      </div>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onClick={onCovidChange}
                            style={{ color: "#35afaf" }}
                            checked={values.covid}
                          />
                        }
                        style={{ visibility: "hidden" }}
                        label="COVID"
                      />
                    </div>
                  </div>
                </FormControl>
              </div>
            )}
            <div className="dashChart">
              <FormControl size="small" fullWidth={true}>
                <div className="flexBox flexEnd">
                  <Button
                    variant="contained"
                    size="medium"
                    type="submit"
                    disabled={noDiagramAlert}
                  >
                    Predict
                  </Button>
                </div>
              </FormControl>
            </div>
          </div>
        </form>

        {loading && (
          <div className="chartOptions" style={{ borderBottom: "none" }}>
            <Loader transparent={true} />
          </div>
        )}

        {filteredDiagrams.map((diagram, index) => (
          <Grid item xs={12} md={12} key={index}>
            <div className="chartBox">
              <div className="chartTitle">
                <h2>{diagram.title}</h2>
                <div
                  style={{
                    padding: "0 10px",
                  }}
                >
                  <p>
                    ✨ Tip: You can zoom in on the graph by selecting an area
                    with your mouse. To zoom out, simply press{" "}
                    <strong>Ctrl + Z</strong>.
                  </p>
                </div>
              </div>
              <iframe
                src={editGrafanaUrlPerEnvironment(diagram.url)}
                width="100%"
                height="500"
                title={`${diagram.title}`}
              ></iframe>
            </div>
          </Grid>
        ))}
        {noDiagramAlert && (
          <Grid item xs={12} md={12}>
            <div
              style={{
                margin: "20px 0",
                maxWidth: "calc(1200px - 2rem)",
              }}
            >
              <Alert
                severity="warning"
                style={{
                  margin: "20px auto",
                  width: "max-content",
                }}
              >
                There are no diagram data available for the selected options at
                the moment.
              </Alert>
            </div>
          </Grid>
        )}
      </div>
    </div>
  );
}

export default GrafanaDiagram;
