// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#resource-list-wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
#resource-list-wrapper h3.metrics-title {
  text-transform: capitalize;
}
#resource-list-wrapper button[type=submit] {
  padding: 14px 24px;
  margin-bottom: 24px;
}`, "",{"version":3,"sources":["webpack://./src/pages/ResourceList/ResourceList.scss"],"names":[],"mappings":"AAAA;EAEI,aAAA;EACA,sBAAA;EACA,SAAA;AAAJ;AAEI;EACI,0BAAA;AAAR;AAGI;EACI,kBAAA;EACA,mBAAA;AADR","sourcesContent":["#resource-list-wrapper {\n\n    display: flex;\n    flex-direction: column;\n    gap: 24px;\n\n    h3.metrics-title {\n        text-transform: capitalize;\n    }\n\n    button[type=submit] {\n        padding: 14px 24px;\n        margin-bottom: 24px;\n    }\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
