import axios from "axios";
import {
  baseUrl,
  deleteOptions,
  getOptions,
  postOptions,
} from "../utilities/fileUploadConfig";

class UploadFileService {
  constructor() {
    this.basePath = "/files";
    this.baseUrl = `${baseUrl}${this.basePath}`;
  }

  uploadFiles(data, token, organisation) {
    return axios(
      postOptions(createOrgUrl(organisation), "", token, data, {
        "Content-Type": "multipart/form-data",
      })
    );
  }

  getFiles(token, organisation) {
    return axios(getOptions(createOrgUrl(organisation), "", token));
  }

  deleteFile(parameters, token, organisation) {
    return axios(
      deleteOptions(createOrgUrl(organisation), "", token, parameters)
    );
  }
}

const uploadFileServiceInstance = new UploadFileService();

function createOrgUrl(organisation) {
  let urlArray = uploadFileServiceInstance.baseUrl.split("-");
  return urlArray[0] + "-" + organisation.toLowerCase() + urlArray[1];
}

export default uploadFileServiceInstance;
