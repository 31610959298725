import React from "react";
import "./Loader.scss";

function Loader({ size, transparent = false }) {
  return (
    <div className={`${size}-loader loader-container ${transparent ? "transparent" : ""}`}>
      <div className="spinner"></div>
    </div>
  );
}

export default Loader;
