import "./DisableArea.scss";

function DisableArea({ disable, children }) {
  return (
    <div className="disable-area-wrapper">
      <div className={`${disable ? "active-disable" : ""}`}>{children}</div>
    </div>
  );
}

export default DisableArea;
