import React, { useContext } from "react";
import { SunriseContext } from "../../App";
import UserService from "../../services/UserService";

function Profile() {
  const { organisation } = useContext(SunriseContext);
  return (
    <div id="profile-page" className="chartBox flexColumn">
      <p>
        Userame:{" "}
        <span>
          <UserService.GetUserName />
        </span>
      </p>
      <p>
        Organisation : <span>{organisation}</span>
      </p>
    </div>
  );
}

export default Profile;
