import { InputLabel, Select } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
  color: "#888 !important",
  "&.Mui-focused": {
    color: "#35afaf !important",
  },
}));

export const StyledSelect = styled(Select)(({ theme }) => ({
  color: "#888",
  "&.MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#35afaf",
    },
    "&:hover fieldset": {
      borderColor: "#35afaf",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#35afaf",
    },
    "&.Mui-disabled fieldset": {
      borderColor: "grey !important",
    },
    "&.Mui-disabled .MuiSvgIcon-root": {
      color: "grey !important",
    },
    "& .MuiSvgIcon-root": {
      color: "#35afaf",
    },
  },
}));
