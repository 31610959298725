// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `\$primaryColor: #35afaf;
\$primaryColorHover: scale-color(\$primaryColor, \$lightness: -10%);
\$primaryColorDisabled: scale-color(\$primaryColor, \$lightness: -50%);
\$secondaryColor: #f9543a;
\$secondaryColorHover: scale-color(\$secondaryColor, \$lightness: -10%);
\$secondaryColorDisabled: scale-color(\$secondaryColor, \$lightness: -50%);

 
 \$primaryLight: #f9f9f9;
 \$secondaryLight: #c9c9c9;
 
 \$primaryDark:#1f1f1f;
 \$secondaryDark:#2d2d2d;

 `, "",{"version":3,"sources":["webpack://./src/tokens.scss"],"names":[],"mappings":"AAAA,sBAAsB;AACtB,gEAAgE;AAChE,mEAAmE;AACnE,wBAAwB;AACxB,oEAAoE;AACpE,uEAAuE;;;CAGtE,sBAAsB;CACtB,wBAAwB;;CAExB,oBAAoB;CACpB,sBAAsB","sourcesContent":["$primaryColor: #35afaf;\n$primaryColorHover: scale-color($primaryColor, $lightness: -10%);\n$primaryColorDisabled: scale-color($primaryColor, $lightness: -50%);\n$secondaryColor: #f9543a;\n$secondaryColorHover: scale-color($secondaryColor, $lightness: -10%);\n$secondaryColorDisabled: scale-color($secondaryColor, $lightness: -50%);\n\n \n $primaryLight: #f9f9f9;\n $secondaryLight: #c9c9c9;\n \n $primaryDark:#1f1f1f;\n $secondaryDark:#2d2d2d;\n\n "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
