import { baseUrl, getOptions } from "../utilities/fileUploadConfig";
import axios from "axios";

class ResourceListService {
  constructor(baseUrl) {
    this.basePath = "/models";
    this.baseUrl = `${baseUrl}${this.basePath}`;
  }

  getMetrics(token, organisation, parameters) {
    return axios(getOptions(createOrgUrl(organisation), "", token, parameters));
  }
}

const resourceListService = new ResourceListService(baseUrl);

function createOrgUrl(organisation) {
  let urlArray = resourceListService.baseUrl.split("-");
  return urlArray[0] + "-" + organisation.toLowerCase() + urlArray[1];
}

export default resourceListService;
