// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#upload-files-wrapper {
  gap: 16px;
  display: flex;
}
#upload-files-wrapper .chartOptions {
  gap: 2rem;
}
#upload-files-wrapper #file-names-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  border: 1px solid white;
  border-radius: 4px;
  padding: 8px 14px;
}
#upload-files-wrapper #file-names-wrapper svg {
  font-size: 24px !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/UploadFile/UploadFile.scss"],"names":[],"mappings":"AAEA;EACI,SAAA;EACA,aAAA;AADJ;AAGI;EACI,SAAA;AADR;AAII;EACI,aAAA;EACA,mBAAA;EACA,QAAA;EACA,uBAAA;EACA,kBAAA;EACA,iBAAA;AAFR;AAIQ;EACI,0BAAA;AAFZ","sourcesContent":["@import '../../tokens.scss';\n\n#upload-files-wrapper {\n    gap: 16px;\n    display: flex;\n\n    .chartOptions {\n        gap: 2rem;\n    }\n\n    #file-names-wrapper {\n        display: flex;\n        align-items: center;\n        gap: 8px;\n        border: 1px solid white;\n        border-radius: 4px;\n        padding: 8px 14px;\n\n        svg {\n            font-size: 24px !important;\n        }\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
