import { Box, Modal } from "@mui/material";
import React from "react";
import { MdOutlineClose } from "react-icons/md";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 400,
  maxWidth: "max-content",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const buttonStyle = {
  border: "none",
  backgroundColor: "transparent",
  fontSize: "28px",
  float: "right",
  cursor: "pointer",
};

function PopUp({
  openPopUp,
  handleClose,
  children,
  title,
  showCloseButton = true,
}) {
  return (
    <Modal open={openPopUp} onClose={handleClose}>
      <Box sx={style}>
        <div>
          {showCloseButton && (
            <button style={buttonStyle} onClick={handleClose}>
              <MdOutlineClose />
            </button>
          )}
        </div>
        {title && <h2>{title}</h2>}
        <div>{children}</div>
      </Box>
    </Modal>
  );
}

export default PopUp;
