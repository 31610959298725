import Keycloak from "keycloak-js";

const keycloak = new Keycloak({
  realm: "Sunrise",
  url: process.env.REACT_APP_KEYCLOAK_URL,
  clientId: "sunrise-client",
  // "ssl-required": "external",
  // "public-client": true,
  // "verify-token-audience": true,
  // "use-resource-role-mappings": true,
  // "confidential-port": 0
});

export default keycloak;