import { Route, Routes, useLocation } from "react-router-dom";
import LandingPage from "../../pages/LandingPage/LandingPage";
import ResourceList from "../../pages/ResourceList/ResourceList";
import GrafanaDiagram from "../../pages/Grafana/GrafanaDiagram";
import Profile from "../../pages/Profile/Profile";
import React, { useContext, useEffect } from "react";
import UploadFile from "../../pages/UploadFile/UploadFile";
import { SunriseContext } from "../../App";

const AppRoutes = ({ setTitle }) => {
  const location = useLocation();
  const { organisation } = useContext(SunriseContext);

  let routesList = [
    {
      path: "/parameters-used",
      title: "Metrics",
      element: <ResourceList />,
    },
    {
      path: "/grafana-diagram",
      title: "Demand Prediction",
      element: <GrafanaDiagram />,
    },
    {
      path: "/profile",
      title: "Profile",
      element: <Profile />,
    },
    {
      path: "/dashboard",
      title: "Dashboard",
      element: <LandingPage />,
    },
    {
      path: "/",
      title: "Dashboard",
      element: <LandingPage />,
    },
    {
      path: "/upload-file",
      title: "Upload File",
      element: <UploadFile />,
    },
  ];

  if (organisation === "ELS") {
    routesList = routesList.filter(
      (element) => element.title !== "Upload File" && element.title !== "Metrics"
    );
  }

  if (organisation === "RTM") {
    routesList = routesList.filter(
      (element) => element.title !== "Metrics"
    );
  }

  useEffect(() => {
    let routeItem = routesList.find((item) => item.path === location.pathname);
    if (routeItem) {
      setTitle(routeItem.title);
    }
  }, [location.pathname]); //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Routes>
      {/* <Route path="/login" element={<SignIn />} /> */}
      {routesList.map((item, index) => (
        <Route path={item.path} element={item.element} key={index} />
      ))}
      <Route path="*" element={<LandingPage />} />
    </Routes>
  );
};
export default AppRoutes;
