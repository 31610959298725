const baseUrl = "https://sunrise-.staging.squaredev.io/v1";

const postOptions = (
  baseUrl,
  endPoint = "",
  token,
  data = {},
  headers = {}
) => {
  return {
    url: `${baseUrl}${endPoint}`,
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      ...headers,
    },
    data: data,
  };
};

const getOptions = (baseUrl, endPoint = "", token, parameters = {}) => {
  return {
    url: `${baseUrl}${endPoint}?` + new URLSearchParams(parameters).toString(),
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
};

const putOptions = (baseUrl, endPoint = "", token, data = {}) => {
  return {
    url: `${baseUrl}${endPoint}`,
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  };
};

const deleteOptions = (baseUrl, endPoint = "", token, parameters = {}) => {
  return {
    url: `${baseUrl}${endPoint}?` + new URLSearchParams(parameters).toString(),
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
};

export { baseUrl, postOptions, getOptions, putOptions, deleteOptions };
