// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.834);
  z-index: 1;
}
.loader-container.transparent {
  background: transparent;
}

.fullscreen-loader {
  height: 100vh;
  position: fixed;
}

.small-loader {
  height: 50vh;
}

.spinner {
  width: 34px;
  height: 34px;
  border: 4px solid;
  border-color: #3d5af1 transparent #3d5af1 transparent;
  border-radius: 50%;
  animation: spin-anim 1.2s linear infinite;
  /* margin-bottom: 150px; */
}

@keyframes spin-anim {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Loader/Loader.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,gCAAA;EACA,UAAA;AACF;AACE;EACE,uBAAA;AACJ;;AAGA;EACE,aAAA;EACA,eAAA;AAAF;;AAEA;EACE,YAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;EACA,iBAAA;EACA,qDAAA;EACA,kBAAA;EACA,yCAAA;EACA,0BAAA;AACF;;AAEA;EACE;IACE,uBAAA;EACF;EACA;IACE,yBAAA;EACF;AACF","sourcesContent":[".loader-container {\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background: rgba(0, 0, 0, 0.834);\n  z-index: 1;\n\n  &.transparent {\n    background: transparent;\n  }\n}\n\n.fullscreen-loader {\n  height: 100vh;\n  position: fixed;\n}\n.small-loader {\n  height: 50vh;\n}\n\n.spinner {\n  width: 34px;\n  height: 34px;\n  border: 4px solid;\n  border-color: #3d5af1 transparent #3d5af1 transparent;\n  border-radius: 50%;\n  animation: spin-anim 1.2s linear infinite;\n  /* margin-bottom: 150px; */\n}\n\n@keyframes spin-anim {\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
