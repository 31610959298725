import React, { useContext, useEffect, useState } from "react";
import "./UploadFile.scss";
import {
  Alert,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  styled,
  tableCellClasses,
} from "@mui/material";
import {
  MdCloudUpload,
  MdCheckCircleOutline,
  MdOutlineDangerous,
  MdCheck,
  MdClear,
  MdOutlineFileCopy,
} from "react-icons/md";
import UploadFileService from "../../services/UploadFileService";
import PopUp from "../../components/PopUp/PopUp";
import Loader from "../../components/Loader/Loader";
import { useKeycloak } from "@react-keycloak/web";
import keycloak from "../../services/keycloak";
import { SunriseContext } from "../../App";

const defaultError = { show: false, message: "" };

function UploadFile() {
  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const { keycloak } = useKeycloak();
  const { organisation } = useContext(SunriseContext);

  const [filesToUpload, setFilesToUpload] = useState(null);
  const [openFileResponsePopUp, setOpenFileResponsePopUp] = useState(false);
  const [response, setResponse] = useState({
    success: [],
    warning: [],
    error: [],
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState({ show: false, message: "" });
  const [files, setFiles] = useState([]);

  const onFileUpload = (event) => {
    setFilesToUpload(event.target.files);
  };

  const onSubmitHandler = (event) => {
    event.preventDefault();
    setError(defaultError);
    if (filesToUpload !== null && filesToUpload?.length > 0) {
      setLoading(true);
      let filesData = new FormData();
      [...filesToUpload].forEach((file) => {
        filesData.append("files", file);
      });
      UploadFileService.uploadFiles(filesData, keycloak.token, organisation)
        .then((response) => {
          setResponse(response.data);
        })
        .catch((error) => {
          setError({
            show: true,
            message: "Failed to upload chosen files. Try again.",
          });
        })
        .finally(() => {
          setLoading(false);
          setOpenFileResponsePopUp(true);
        });
    }
  };

  useEffect(() => {
    importFiles();
  }, [response, organisation]); //eslint-disable-line react-hooks/exhaustive-deps

  const importFiles = () => {
    if (organisation) {
      UploadFileService.getFiles(keycloak.token, organisation)
        .then((response) => {
          setFiles(response.data);
          setError(defaultError);
        })
        .catch((error) => {
          setFiles([]);
          setError({ show: true, message: "Failed to load imported files." });
          setOpenFileResponsePopUp(true);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <div id="upload-files-wrapper" className="flexbox flex-column">
      <div className="dashChart">
        <div className="flexBox">
          <div className="chartOptions chartBox grafana-inner flex-column">
            <h2>Select one or more files to upload.</h2>
            <div style={{ visibility: loading ? "visible" : "hidden" }}>
              <Loader transparent={true} />
            </div>

            <form className="flexBox" onSubmit={onSubmitHandler}>
              <Button
                component="label"
                variant="contained"
                startIcon={<MdCloudUpload />}
                color="secondary"
                disabled={loading}
              >
                Select Files
                <VisuallyHiddenInput
                  type="file"
                  multiple
                  onChange={onFileUpload}
                />
              </Button>
              <Button
                variant="contained"
                type="submit"
                disabled={filesToUpload === null || filesToUpload?.length === 0}
              >
                Upload Files
              </Button>
            </form>

            {filesToUpload && (
              <div id="file-names-wrapper">
                <MdOutlineFileCopy />
                <>
                  {Object.keys(filesToUpload).map((key, index) => (
                    <span key={index}>
                      {filesToUpload[key].name}{" "}
                      {index !== filesToUpload.length - 1 ? " , " : ""}
                    </span>
                  ))}
                </>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="dashChart">
        <div className="chartBox">
          <FilesTable
            files={files}
            deleteCallback={importFiles}
            organisation={organisation}
          />
        </div>
      </div>
      <PopUp
        openPopUp={openFileResponsePopUp}
        handleClose={() => setOpenFileResponsePopUp(false)}
        title={"Upload/Import Files Response"}
      >
        {error.show ? (
          <Alert severity="error">{error.message}</Alert>
        ) : (
          <FileResponseTable data={response} organisation={organisation} />
        )}
      </PopUp>
    </div>
  );
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "black",
    color: "white",
    fontSize: 14,
    textTransform: "capitalize",
    textAlign: "center",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    borderCollapse: true,
    border: "none",
    textAlign: "center",
  },
}));

const UploadFileTableRow = styled(TableRow)(({ color }) => ({
  backgroundColor: color,
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: "#1F1F1F",
  color: "white",
}));

function FileResponseTable({ data, organisation }) {
  const formatKeyString = (key) => {
    let value = key.replace("n_", "");
    return value.replaceAll("_", " ");
  };

  const createFileResponseColumnsHeads = (data) => {
    return (
      <>
        {Object.keys(data[0]).map((key, index) => (
          <StyledTableCell key={index}>{formatKeyString(key)}</StyledTableCell>
        ))}
      </>
    );
  };

  const createFileResponseColumnsCells = (file) => {
    return (
      <>
        {Object.keys(file).map((key, index) => (
          <StyledTableCell key={index}>
            {formatDataCellContent(file, key)}
          </StyledTableCell>
        ))}
      </>
    );
  };

  const createResponseMessage = (name, key) => {
    if (key === "has_invalid_type") {
      return (
        "The " +
        name +
        " type is not supported. Please upload a valid file type."
      );
    } else if (key === "is_large_file") {
      return (
        "The " +
        name +
        " is too large. Please upload a file smaller than the 10MB."
      );
    } else if (key === "is_empty") {
      return "The " + name + " is empty. Please upload a file with data.";
    } else if (key === "has_invalid_format") {
      if (organisation === "ACO") {
        return (
          "The " +
          name +
          " format is invalid. Required columns: month, year, place, value."
        );
      } else if (["CAF", "HQM", "INS"].includes(organisation)) {
        return (
          "The " +
          name +
          " format is invalid. Required columns: date, category, value."
        );
      } else {
        return "";
      }
    } else if (key === "n_missing_months") {
      return "The " + name + " is missing data in the 'month' column.";
    } else if (key === "n_missing_years") {
      return "The " + name + " is missing data in the 'year' column.";
    } else if (key === "n_missing_places") {
      return "The " + name + " is missing data in the 'place' column.";
    } else if (key === "n_invalid_dates") {
      return (
        "The " +
        name +
        " contains invalid dates. Please correct the date format."
      );
    } else if (key === "n_invalid_places") {
      return " The " + name + " is missing data in the 'place' column.";
    } else if (key === "n_invalid_categories") {
      return "The " + name + " is missing data in the 'category' column. ";
    } else if (key === "n_invalid_values") {
      return (
        " The " +
        name +
        " contains invalid values in ‘value’ column. Please correct the values format."
      );
    } else if (key === "has_invalid_name") {
      if (organisation === "HQM") {
        return (
          "The " +
          name +
          " name is invalid. Ensure it contains one of the following terms exclusively: 'drugs', 'consumables', 'patients', or 'oxygen'."
        );
      } else if (organisation === "INS") {
        return (
          "The " +
          name +
          " name is invalid. Ensure it contains one of the following terms exclusively: 'documents' or 'tickets'."
        );
      } else {
        return "";
      }
    }
  };

  const formatDataCellContent = (file, key) => {
    if (typeof file[key] === "boolean") {
      if (file[key]) {
        return (
          <Tooltip title={createResponseMessage(file.filename, key)}>
            <span>
              <MdClear />
            </span>
          </Tooltip>
        );
      } else {
        return <MdCheck />;
      }
    } else if (typeof file[key] === "number" && key !== "size") {
      if (file[key] > 0) {
        return (
          <Tooltip title={createResponseMessage(file.filename, key)}>
            <span>{file[key]}</span>
          </Tooltip>
        );
      } else {
        return file[key];
      }
    } else {
      return file[key];
    }
  };

  const checkFileValidity = (file) => {
    let invalidBooleanAttr = [
      "has_invalid_type",
      "is_large_file",
      "is_empty",
      "has_invalid_format",
    ];
    let inavlidNumericAttr = [
      "n_missing_months",
      "n_missing_years",
      "n_missing_places",
      "n_invalid_values",
      "n_invalid_dates",
      "n_invalid_categories",
      "n_invalid_places",
    ];

    let validity = true;
    Object.keys(file).forEach((key) => {
      if (invalidBooleanAttr.includes(key)) {
        if (file[key] === true) {
          validity = false;
        }
      }

      if (inavlidNumericAttr.includes(key)) {
        if (file[key] > 0) {
          validity = false;
        }
      }
    });
    return validity;
  };

  return (
    <div>
      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: 700 }}
          aria-label="customized table"
          style={{ borderCollapse: "collapse" }}
        >
          <TableHead>
            <StyledTableRow>
              <StyledTableCell></StyledTableCell>
              {createFileResponseColumnsHeads(data)}
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {data.map((item, index) => (
              <UploadFileTableRow
                key={index}
                color={`${checkFileValidity(item) ? "#C4E5CA" : "#FACECD"}`}
              >
                <StyledTableCell>
                  <Tooltip title="Upload Success">
                    <div>
                      {checkFileValidity(item) ? (
                        <MdCheckCircleOutline />
                      ) : (
                        <MdOutlineDangerous />
                      )}
                    </div>
                  </Tooltip>
                </StyledTableCell>
                {createFileResponseColumnsCells(item)}
              </UploadFileTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <br />
      {data.map((item, index) => (
        <div key={index}>
          {checkFileValidity(item, index) && (
            <Alert severity="success" key={index}>
              {item.filename} upload successful. The file contains{" "}
              {item.n_missing_values} missing values, {item.n_new_records} new
              records, and {item.n_updated_records} updated records.
            </Alert>
          )}
        </div>
      ))}
    </div>
  );
}

const StyledFilesTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // color: "white",
    fontSize: 16,
    textTransform: "capitalize",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
    borderCollapse: true,
    border: "none",
  },
}));

const StyledFilesTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function FilesTable({ files = [], deleteCallback, organisation }) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [filesToDisplay, setFilesToDisplay] = useState([]);
  const [deleteWarning, setDeleteWarning] = useState(false);
  const [deleteRequest, setDeleteRequest] = useState({});
  const [deleteError, setDeleteError] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    var start = page * rowsPerPage;
    var end = start + rowsPerPage;
    setFilesToDisplay(files.slice(start, end));
  }, [files, page, rowsPerPage]);

  const onDeleteFile = () => {
    setDeleteWarning(false);
    UploadFileService.deleteFile(deleteRequest, keycloak.token, organisation)
      .catch((error) => {
        setDeleteError(true);
      })
      .finally(() => {
        deleteCallback();
      });
  };

  const onDeleteHandler = (e) => {
    const request = {
      filename: e.target.dataset.filename,
    };
    setDeleteRequest(request);
    setDeleteWarning(true);
  };

  return (
    <div>
      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: 700 }}
          aria-label="customized table"
          style={{ borderCollapse: "collapse" }}
        >
          <TableHead>
            <StyledTableRow>
              <StyledFilesTableCell>Filename</StyledFilesTableCell>
              <StyledFilesTableCell align="center">Size</StyledFilesTableCell>
              {/* <StyledFilesTableCell align="center">Status</StyledFilesTableCell> */}
              <StyledFilesTableCell></StyledFilesTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {filesToDisplay.map((file, index) => (
              <StyledFilesTableRow key={index}>
                <StyledTableCell>{file.filename}</StyledTableCell>
                <StyledTableCell align="center">{file.size}</StyledTableCell>
                {/* <StyledTableCell align="center">{file.message}</StyledTableCell> */}
                <StyledTableCell align="center">
                  <Button
                    variant="contained"
                    className="delete"
                    style={{ backgroundColor: "#ef4444" }}
                    onClick={onDeleteHandler}
                    data-filename={file.filename}
                  >
                    Delete
                  </Button>
                </StyledTableCell>
              </StyledFilesTableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={files?.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
      <PopUp
        openPopUp={deleteError}
        handleClose={() => {
          setDeleteError(false);
        }}
        title={"Error!"}
      >
        <div className="flexBox flexColumn">
          <Alert severity="error">File deletion failed! Try again.</Alert>
        </div>
      </PopUp>
      <PopUp
        openPopUp={deleteWarning}
        handleClose={() => {
          setDeleteWarning(false);
        }}
        title={"Warning!"}
      >
        <div className="flexBox flexColumn">
          <Alert severity="warning">
            Are you sure you want to delete this file? This action can not be
            undone!
          </Alert>
          <div className="flexBox flexEnd ">
            <Button
              variant="contained"
              onClick={() => {
                setDeleteWarning(false);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              className="delete"
              style={{ backgroundColor: "#ef4444" }}
              onClick={onDeleteFile}
            >
              Delete
            </Button>
          </div>
        </div>
      </PopUp>
    </div>
  );
}

export default UploadFile;
