import React, { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import organisationData from "../../data/ciiData.json";
import { SunriseContext } from "../../App";
import { Alert, FormControl, MenuItem } from "@mui/material";
import {
  StyledInputLabel,
  StyledSelect,
} from "../../components/FormElements/CustomSelect";

function LandingPage() {
  const { organisation } = useContext(SunriseContext);
  const [timePeriods, setTimePeriods] = useState([]);
  const [primaryDropdownData, setPrimaryDropdownData] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedValues, setSelectedValue] = useState({
    primary: "",
    secondary: "",
    timePeriod: "",
  });
  const [diagrams, setDiagrams] = useState([]);
  const [noDiagramAlert, setNoDiagramAlert] = useState(false);

  const timePeriodOptions = {
    hourly: { id: "hourly", name: "Hourly" },
    daily: { id: "daily", name: "Daily" },
    monthly: { id: "monthly", name: "Monthly" },
  };

  const sortItems = (items) => {
    return items.sort((a, b) => {
      if (a.name === "All places") return 1;
      if (b.name === "All places") return -1;
      if (a.name === "Global") return -1;
      if (b.name === "Global") return 1;
      return a.name.localeCompare(b.name);
    });
  };

  useEffect(() => {
    if (organisationData && organisation) {
      const data = organisationData.find(
        (element) => element.name === organisation
      );
      if (data.dropdownData.options.length === 1) {
        setSelectedItem(data.dropdownData.options[0]);
        setSelectedValue((prevValues) => ({
          ...prevValues,
          primary: data.dropdownData.options[0].id,
        }));
      }
      if (["TT", "RTM"].includes(organisation)) {
        let filteredData = { ...data.dropdownData };
        filteredData.options = data.dropdownData.options.filter(
          (item) => item.showInDashboard === true
        );
        setPrimaryDropdownData(filteredData);
      } else {
        setPrimaryDropdownData(data.dropdownData);
      }
    }
  }, [organisation]);

  const onPrimaryDropdownChange = (e) => {
    const item = primaryDropdownData.options.find(
      (element) => element.id === e.target.value
    );
    setSelectedItem(item ?? null);
    setSelectedValue((prevState) => ({
      ...prevState,
      primary: e.target.value,
      secondary: "",
      timePeriod: "",
    }));
    clearStates(true);
  };

  const onSecondaryDropdownChange = (e) => {
    setSelectedValue((prevState) => ({
      ...prevState,
      secondary: e.target.value,
      timePeriod: "",
    }));
    clearStates(true);
  };

  const handleDiagrams = (historicDiagrams) => {
    if (organisation === "ELS") {
      let tempDiagrams = historicDiagrams.map((diagram) => ({ ...diagram }));
      let startDate = new Date("2022-01-01");
      for (let i = 0; i < tempDiagrams.length; i++) {
        let url = new URL(tempDiagrams[i].url);
        url.searchParams.set("from", startDate.getTime());
        url.searchParams.delete("to");
        tempDiagrams[i].url = url.href;
      }
      setDiagrams(tempDiagrams);
    } else {
      for (let i = 0; i < historicDiagrams.length; i++) {
        let url = new URL(historicDiagrams[i].url);
        url.searchParams.delete("to");
        historicDiagrams[i].url = url.href;
      }
      setDiagrams(historicDiagrams);
    }
    setNoDiagramAlert(historicDiagrams.length === 0);
  };

  const onHandleTimePeriodFieldChange = (e) => {
    let time = e.target.value;
    onTimePeriodChange(time);
  }

  const onTimePeriodChange = (time) => {
    setSelectedValue((prevState) => ({
      ...prevState,
      timePeriod: time,
    }));
    if (time !== "") {
      if (selectedItem.subOptions) {
        let tempDiagrams = selectedItem.subOptions.find(
          (item) => item.id === selectedValues.secondary
        );
        if (tempDiagrams) {
          let historicDiagrams = tempDiagrams[time].filter(
            (element) => element.historic === true
          );
          handleDiagrams(historicDiagrams);
        }
      } else {
        let historicDiagrams = selectedItem[time].filter(
          (element) => element.historic === true
        );
        handleDiagrams(historicDiagrams);
      }
    } else {
      clearStates();
    }
  };

  useEffect(() => {
    if (selectedItem) {
      if (selectedItem.subOptions && selectedItem.subOptions.length > 0) {
        if (selectedValues.secondary !== "") {
          let option = selectedItem.subOptions.find(
            (element) => element.id === selectedValues.secondary
          );
          if (option) {
            let tempTimePeriods = [];
            Object.keys(timePeriodOptions).forEach((key) => {
              let diagrams = option[key].filter(
                (element) => element.historic === true
              );
              if (diagrams && diagrams.length > 0) {
                tempTimePeriods.push(timePeriodOptions[key]);
              }
            });
            handleTimePeriodChange(tempTimePeriods);
            setNoDiagramAlert(tempTimePeriods.length === 0);
          }
        }
      } else {
        let tempTimePeriods = [];
        Object.keys(timePeriodOptions).forEach((key) => {
          let diagrams = selectedItem[key].filter(
            (element) => element.historic === true
          );
          if (diagrams && diagrams.length > 0) {
            tempTimePeriods.push(timePeriodOptions[key]);
          }
        });
        handleTimePeriodChange(tempTimePeriods);
        setNoDiagramAlert(tempTimePeriods.length === 0);
      }
    }
  }, [selectedItem, selectedValues]); //eslint-disable-line react-hooks/exhaustive-deps

  const clearStates = (clearTime = false) => {
    setDiagrams([]);
    setNoDiagramAlert(false);
    if (clearTime) {
      setTimePeriods([]);
    }
  };

  const handleTimePeriodChange = (times) => {
    setTimePeriods(times);
    if(times.length === 1) {
      onTimePeriodChange(times[0].id)
    }
  }

  const editGrafanaUrlPerEnvironment = (url) => {
    let baseUrl = url
      .split("//")[1]
      .substring(url.split("//")[1].indexOf("/") + 1);
    return process.env.REACT_APP_GRAFANA_URL + "/" + baseUrl;
  };

  return (
    <div>
      <div className="dashChart">
        <div className="flexBox">
          <Box sx={{ width: "100%" }}>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={12} md={12}>
                <div
                  className="chartBox chartOptions flex-column"
                  style={{ gap: "16px", alignItems: "flex-start" }}
                >
                  <h2 className="grafanaTitle">Dashboard</h2>
                  <p>
                    Choose the desired categories & time period from the options
                    below to get the corresponding graph.{" "}
                  </p>
                  <div className="dashChart flexBox">
                    {primaryDropdownData && (
                      <>
                        <FormControl fullWidth={true}>
                          <StyledInputLabel
                            htmlFor={`${primaryDropdownData.primaryTitle}-1`}
                          >
                            {primaryDropdownData.primaryTitle}
                          </StyledInputLabel>
                          <StyledSelect
                            labelId={`${primaryDropdownData.primaryTitle}-1`}
                            label={primaryDropdownData.primaryTitle}
                            onChange={onPrimaryDropdownChange}
                            value={selectedValues.primary}
                          >
                            <MenuItem value=""></MenuItem>
                            {sortItems(primaryDropdownData.options).map(
                              (item, index) => (
                                <MenuItem
                                  key={`${index}-${item}`}
                                  value={item.id}
                                >
                                  {item.name}
                                </MenuItem>
                              )
                            )}
                          </StyledSelect>
                        </FormControl>
                        {selectedItem && selectedItem.subOptions && (
                          <FormControl fullWidth={true}>
                            <StyledInputLabel
                              htmlFor={`${primaryDropdownData.secondaryTitle}-2`}
                            >
                              {primaryDropdownData.secondaryTitle}
                            </StyledInputLabel>
                            <StyledSelect
                              labelId={`${primaryDropdownData.secondaryTitle}-2`}
                              label={primaryDropdownData.secondaryTitle}
                              disabled={
                                selectedItem === null ||
                                selectedItem.subOptions.length === 0
                              }
                              onChange={onSecondaryDropdownChange}
                              value={selectedValues.secondary}
                            >
                              {selectedItem && <MenuItem value=""></MenuItem>}
                              {selectedItem &&
                                sortItems(selectedItem.subOptions).map(
                                  (item, index) => (
                                    <MenuItem
                                      key={`${index}-${item.id}`}
                                      value={item.id}
                                    >
                                      {item.name}
                                    </MenuItem>
                                  )
                                )}
                            </StyledSelect>
                          </FormControl>
                        )}
                        <FormControl fullWidth={true}>
                          <StyledInputLabel htmlFor="timePeriod">
                            Time period
                          </StyledInputLabel>
                          <StyledSelect
                            labelId="timePeriod"
                            label="timePeriod"
                            onChange={onHandleTimePeriodFieldChange}
                            value={selectedValues.timePeriod}
                            disabled={timePeriods.length === 0}
                          >
                            <MenuItem value=""></MenuItem>
                            {timePeriods.map((period, index) => (
                              <MenuItem
                                key={`${index}-${period}`}
                                value={period.id}
                              >
                                {period.name}
                              </MenuItem>
                            ))}
                          </StyledSelect>
                        </FormControl>
                      </>
                    )}
                  </div>
                </div>
              </Grid>
              {diagrams.map((diagram, index) => (
                <Grid item xs={12} md={12} key={index}>
                  <div className="chartBox">
                    <div className="chartTitle">
                      <h2>{diagram.title}</h2>
                      <div
                        style={{
                          padding: "0 10px",
                        }}
                      >
                        <p>
                          ✨ Tip: You can zoom in on the graph by selecting an
                          area with your mouse. To zoom out, simply press{" "}
                          <strong>Ctrl + Z</strong>.
                        </p>
                      </div>
                    </div>
                    <iframe
                      src={editGrafanaUrlPerEnvironment(diagram.url)}
                      width="100%"
                      height="500"
                      title={`${diagram.title}`}
                    ></iframe>
                  </div>
                </Grid>
              ))}
              {noDiagramAlert && (
                <Grid item xs={12} md={12}>
                  <div
                    style={{
                      margin: "20px 0",
                      maxWidth: "calc(1200px - 2rem)",
                    }}
                  >
                    <Alert
                      severity="warning"
                      style={{
                        margin: "20px auto",
                        width: "max-content",
                      }}
                    >
                      There are no diagram data available for the selected
                      options at the moment.
                    </Alert>
                  </div>
                </Grid>
              )}
            </Grid>
          </Box>
        </div>
      </div>
    </div>
  );
}

export default LandingPage;
