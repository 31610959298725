import "./App.scss";
import React, { createContext, useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import SideBar from "./components/SideBar/SideBar";
import Loader from "./components/Loader/Loader";
import { useKeycloak } from "@react-keycloak/web";
import NavigationBar from "./components/Navigation/NavigationBar";
import AppRoutes from "./components/Routes/AppRoutes";
import PopUp from "./components/PopUp/PopUp";
import { Alert } from "@mui/material";

export const SunriseContext = createContext();

function App() {
  const [navBarTitle, setNavBarTitle] = useState("");
  const [themeState, setThemeState] = useState(true);
  const { keycloak, initialized } = useKeycloak();
  const [organisation, setOrganisation] = useState(null);
  const [openCiiPopUp, setOpenCiiPopUp] = useState(false);

  useEffect(() => {
    const validOrganisations = ["ACO", "CAF", "TT", "RTM", "INS", "HQM", "ELS"];
    keycloak
      .loadUserInfo()
      .then((response) => {
        let org = response.groups.filter((element) =>
          element.includes("/organisations/")
        );
        if (org.length === 1) {
          let index = String(org[0]).lastIndexOf("/");
          var group = org[0].slice(index + 1);
          if (validOrganisations.includes(group)) {
            setOrganisation(group);
            setOpenCiiPopUp(false);
          } else {
            setOpenCiiPopUp(true);
          }
        } else {
          setOpenCiiPopUp(true);
        }
      })
      .catch((error) => {
        setOrganisation(null);
      });
  }, [keycloak, initialized]);

  return (
    <SunriseContext.Provider value={{ organisation, themeState }}>
      {!keycloak.authenticated ? (
        <Loader size="fullscreen" />
      ) : (
        <BrowserRouter>
          <div id="App" className={`App ${themeState ? "darkMode" : ""}`}>
            <NavigationBar title={navBarTitle} setThemeState={setThemeState} />
            <div className="app-main-content">
              <SideBar disable={organisation === null} />
              {organisation !== null ? (
                <div className="app-content">
                  <AppRoutes setTitle={setNavBarTitle} />
                </div>
              ) : null}
            </div>
            <PopUp
              openPopUp={openCiiPopUp}
              handleClose={() => {
                setOpenCiiPopUp(false);
              }}
              showCloseButton={true}
              title={"Failed to import organisation information!"}
            >
              <Alert severity="warning">
                Your organisation information are not currently available.
                Please contact system administrator in order to resolve this
                issue.
              </Alert>
            </PopUp>
          </div>
        </BrowserRouter>
      )}
    </SunriseContext.Provider>
  );
}

export default App;
