import keycloak from "./keycloak";

// Maybe store some user data in the future(?)
// Test: Username string to load on navigation
// upadate if we need to store user data
var userName = "";

const tokenHandler = async () => {
  try {
    await keycloak
    .updateToken();

    localStorage.setItem(
      "kc_tok",
      JSON.stringify({
        token: keycloak.token,
        refreshToken: keycloak.refreshToken,
        idToken: keycloak.idToken,
      })
    );
    sessionStorage.setItem(
      "kc_tok",
      JSON.stringify({
        token: keycloak.token,
        refreshToken: keycloak.refreshToken,
        idToken: keycloak.idToken,
      })
    );

    userName = keycloak.tokenParsed.preferred_username;
  } catch {
    doLogout();
  }
}

function doLogout() {
  clearUserTokens();
  keycloak.logout();
}

function clearUserTokens() {
  localStorage.removeItem("kc_tok");
  sessionStorage.removeItem("kc_tok");
}

function isLoggedIn() {
  return keycloak.authenticated;
}

function getToken() {
  return keycloak.token;
}
const eventHandler = (event, error) => {
  // console.log('onKeycloakEvent', event, error)
}

const GetUserName = () => {
  return userName
}

// TODO: QA TEST - expired token
keycloak.onTokenExpired = () => {
  keycloak
    .updateToken()
    .then(() => {
      sessionStorage.setItem(
        "kc_tok",
        JSON.stringify({
          token: keycloak.token,
          refreshToken: keycloak.refreshToken,
          idToken: keycloak.idToken,
        })
      );
    })
    .catch(() => {
      // console.log("token expired, error updating token");
      keycloak.logout();
    });
};

const UserService = {
  tokenHandler,
  doLogout,
  clearUserTokens,
  isLoggedIn,
  getToken,
  eventHandler,
  GetUserName
}

export default UserService;