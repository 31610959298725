// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.disable-area-wrapper .active-disable {
  pointer-events: none;
  opacity: 0.4;
}`, "",{"version":3,"sources":["webpack://./src/components/DisableArea/DisableArea.scss"],"names":[],"mappings":"AAEI;EACI,oBAAA;EACA,YAAA;AADR","sourcesContent":[".disable-area-wrapper {\n\n    .active-disable {\n        pointer-events: none;\n        opacity: 0.4;  \n    }\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
